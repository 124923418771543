// @flow strict
import React from "react";
import Author from "./Author";
import Contacts from "./Contacts";
import Copyright from "./Copyright";
import Menu from "./Menu";
import styles from "./Sidebar.module.scss";
import { useSiteMetadata } from "../../hooks";

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { copyright, menu } = useSiteMetadata();
  const author = {
    name: "Christopher Goh",
    photo: "/photo.jpg",
    bio:
      "I write code, I drink coffee, I have fun. Sometimes all of them at once.",
    contacts: {
      email: "chris@christopher.sg",
      facebook: "chrisgzf",
      telegram: "",
      twitter: "chrisgzf",
      github: "chrisgzf",
      rss: "",
      vkontakte: "",
      linkedin: "chrisgzf",
      instagram: "quishterfur",
      line: "",
      gitlab: "",
      weibo: "",
      codepen: "",
      youtube: "",
      soundcloud: "",
    },
  };

  return (
    <div className={styles["sidebar"]}>
      <div className={styles["sidebar__inner"]}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
